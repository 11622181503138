import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

	jQuery(document).ready(function($) {

		// 1. Correspondances attribut -> valeur/label par défaut
		var defaultMap = {
			'attribute_pa_exemplaires':       { value: '0',        label: 'Option par défaut 1' },
			'attribute_pa_type-de-papier':    { value: 'digital',  label: 'Option par défaut 2' },
			'attribute_pa_modeles':           { value: 'digital',  label: 'Option par défaut 3' },
			'attribute_pa_format':            { value: 'digital',       label: 'Option par défaut 4' },
			'attribute_pa_orientation':       { value: 'digital', label: 'Option par défaut 5' },
			'attribute_pa_qr-code':           { value: 'digital',      label: 'Option par défaut 6' },
			'attribute_pa_support-de-papier': { value: 'digital', label: 'Option par défaut 7' }
		};

		// 2. Écoute de l'événement .change() sur ton toggle
		$('#toggle-variations-btn').change(function () {
			// On toggle la classe active
			$('.variations').toggleClass('active');

			// === CAS 1 : .variations N'A PAS la classe active après le toggle (bloc "fermé") ===
			if (!$('.variations').hasClass('active')) {

				console.log('Réinitialisation des valeurs par défaut...');

				// Parcourt tous les selects dont le name commence par "attribute_pa_"
				$('.variations select[name^="attribute_pa_"]').each(function() {
					var $select  = $(this);
					var attrName = $select.attr('name'); // ex: "attribute_pa_exemplaires"

					// Récupère la valeur/label par défaut dans defaultMap
					var def = defaultMap[attrName];
					if (!def) {
						// Fallback si l'attribut n'est pas dans defaultMap
						def = { value: '0', label: 'Option par défaut (générique)' };
					}

					// On ajoute l'option par défaut si elle n’existe pas
					if (!$select.find('option[value="' + def.value + '"]').length) {
						$select.append('<option value="' + def.value + '">' + def.label + '</option>');
					}

					// Si WooCommerce insère une option vide (value=""), on peut l'enlever
					// pour être sûr que la valeur par défaut soit bien sélectionnée.
					// (Tu peux commenter cette ligne si tu souhaites garder "Choisir une option")
					$select.find('option[value=""]').remove();

					// On force la sélection de l'option par défaut
					$select.val(def.value);

					// On déclenche ensuite le change pour mettre à jour la variation WooCommerce
					$select.trigger('change');
				});

			} else {
				// === CAS 2 : .variations A la classe active (bloc "ouvert") ===
				console.log('Suppression des options par défaut...');

				// On supprime les options par défaut
				$('.variations select[name^="attribute_pa_"]').each(function() {
					var $select  = $(this);
					var attrName = $select.attr('name');

					var def = defaultMap[attrName];
					if (!def) {
						def = { value: '0', label: 'Option par défaut (générique)' };
					}

					$select.find('option[value="' + def.value + '"]').remove();
				});
			}
		});
	});
});